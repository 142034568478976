<template>

  <Accordion class="mt-2" v-model:activeIndex="activeIndex" v-if="verPrimeraInstancia">
    <AccordionTab header="PRIMERA INSTANCIA" v-if="verPrimeraInstancia">

      <div class="grid grid-cols-4 text-sm grid-cols-5 grid-rows-2 gap-2">
        <div class="flex flex-col">
          <span class="font-bold">Categorización: </span>
          <span>{{ fallosByTutela.estadosFallo.name }}</span>
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Fecha Notificacion: </span>
          <span>{{ fallosByTutela.fechaNotificacion }}</span>
        </div>
        <div class="flex flex-col col-span-2 row-span-2">
          <span class="font-bold">Observaciones: </span>
          <span>{{ fallosByTutela.observacion }}</span>
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Acciones: </span>
          <div class="flex gap-2">
            <Button v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" icon="pi pi-pencil" v-tooltip.top="'Editar fallo'" class="p-button-rounded p-button-warning"
                    @click="onEditar(fallosByTutela.id)"/>
            <Button v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" icon="pi pi-plus" v-tooltip.top="'Crear impugnación'" class="p-button-rounded p-button-help"
                    @click="crearImpugnacion()"/>
            <Button icon="pi pi-file-pdf" v-tooltip.top="'Ver Soportes'" class="p-button-rounded p-button-info"
                    @click="verSoportesFallo(fallosByTutela.paths, fallosByTutela.id)"/>
          </div>
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Id: </span>
          <span>{{ fallosByTutela.id }}</span>
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Fecha Vencimiento: </span>
          <span>{{ fallosByTutela.fechaVencimiento }}</span>
        </div>
        <div class="flex flex-col">
<!--          <span class="font-bold">Documentos: </span>-->
<!--          <template v-if="fallosByTutela.paths.length > 0">-->
<!--            <section v-for="(item, key) in fallosByTutela.paths" :key="key">-->
<!--              <span class="cursor-pointer hover:underline"-->
<!--                    @click="verPdf(item.urlPath)"-->
<!--                    style="color: #0b7ad1"-->
<!--              >-->
<!--                {{ item.fileName }}.pdf-->
<!--              </span>-->
<!--            </section>-->
<!--          </template>-->
        </div>
      </div>

    </AccordionTab>

    <AccordionTab header="IMPUGNACIÓN" v-if="verImpugnacion">

      <div v-if="addImpugnacion" class="flex flex-col items-center gap-2">
        <span class="font-medium text-lg">Crear Impugnación</span>
        <div class="grid grid-cols-3 grid-rows-2 gap-2 mb-2">
          <div class="flex flex-col justify-center">
            <label class="font-medium text-sm">Nombre:</label>
            <InputText v-model="model.nombre" />
            <MessageError :text="errors.nombre"/>
          </div>
          <div class="flex flex-col justify-center">
            <label class="font-medium text-sm">Tipo y numero de documento:</label>
            <div class="flex gap-2">
              <Dropdown v-model="model.tipoDoc"
                        :options="docTipos"
                        optionLabel="code"
                        optionValue="code"
                        class="w-2/6"
              />
              <InputText v-model="model.numeroIdentificacion" class="w-4/6" />
            </div>
            <MessageError :text="errors.numeroIdentificacion"/>
            <MessageError :text="errors.tipoIdentificacion"/>
          </div>
          <div class="flex flex-col justify-center">
            <label class="font-medium text-sm">Fecha de Impugnación:</label>
            <input class="p-inputtext w-full"
                   type="date"
                   v-model="model.fechaImpugnacion"
            />
            <MessageError :text="errors.fechaImpugnacion"/>
          </div>
          <div class="flex flex-col col-span-3">
            <label class="font-medium text-sm">Observaciones</label>
            <Textarea
              v-model="model.observaciones"
              placeholder="Observaciones"
              class="w-full"/>
            <MessageError :text="errors.observaciones"/>
          </div>
          <div class="flex justify-between items-center" v-for="(item, key) in adjuntos" :key="key">
            <div class="flex justify-between items-center mt-2">
              <i class="pi pi-file-pdf mr-2 text-black"></i>
              <p>{{ item.fileName }}</p>
            </div>
            <div>
              <button><i class="pi pi-trash mr-2 text-red-600"></i></button>
            </div>
          </div>
          <div></div>
        </div>
        <div class="flex gap-2 w-1/3">
          <button icon="pi pi-external-link"
                  @click="openMaximizable"
                  class="w-full py-2 bg-white hover:bg-gray-200 border border-solid border-gray-400">
            Agregar soporte
          </button>
          <Button v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" icon="pi pi-save"
                  label="Guardar"
                  class="w-full"
                  @click="onGuardarImpugnacion()"
          />
          <Button icon="pi pi-times"
                  label="Cancelar"
                  class="w-full p-button-warning"
                  @click="addImpugnacion = false"
          />
        </div>
        <div class="mt-4 mb-4 bg-blue-300 h-0.5 w-full" v-show="fallosByTutela.Impugnacion.length > 0"></div>
      </div>

      <div class="flex flex-col" v-for="(impugnacion, key) in fallosByTutela.Impugnacion" :key="key">
        <div class="grid grid-cols-4 text-sm grid-cols-5 grid-rows-2 gap-2">
          <div class="flex flex-col">
            <span class="font-bold">Id: </span>
            <span>{{ impugnacion.id }}</span>
          </div>
          <div class="flex flex-col">
            <span class="font-bold">Fecha Impugnación: </span>
            <span>{{ impugnacion.fechaImpugnacion }}</span>
          </div>
          <div class="flex flex-col col-span-2 row-span-2">
            <span class="font-bold">Observaciones: </span>
            <span>{{ impugnacion.observaciones }}</span>
          </div>
          <div class="flex flex-col">
            <span class="font-bold">Acciones: </span>
            <div class="flex gap-2">
              <Button v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" icon="pi pi-pencil" v-tooltip.top="'Editar fallo'" class="p-button-rounded p-button-warning"
                      @click="onEditarImpugnacion(impugnacion.id)"/>
              <Button v-if="!impugnacion.Fallo && $can('pharmasan.administrativa.juridica.tutelas.access')" icon="pi pi-plus" v-tooltip.top="'Crear Fallo'" class="p-button-rounded p-button-help"
                      @click="crearSegundaInstancia(impugnacion.id)"/>
              <Button icon="pi pi-file-pdf" v-tooltip.top="'Ver Soportes'" class="p-button-rounded p-button-info"
                      @click="verSoportes(impugnacion.paths, impugnacion.id)"/>
            </div>
          </div>
          <div class="flex flex-col">
            <span class="font-bold">Creado Por: </span>
            <span>{{ impugnacion.createdByNameImpugnaciones.fullName }}</span>
          </div>
          <div class="flex flex-col">
            <span class="font-bold">Nombre: </span>
            <span>{{ impugnacion.nombre }}</span>
          </div>

          <div class="flex flex-col">
<!--            <span class="font-bold">Documentos: </span>-->
<!--            <template v-if="impugnacion.paths.length > 0">-->
<!--              <div v-if="impugnacion.paths[0].fileName !== null">-->
<!--                <section v-for="(item, key) in impugnacion.paths" :key="key">-->
<!--                <span class="cursor-pointer hover:underline"-->
<!--                      @click="verPdf(item.urlPath)"-->
<!--                      style="color: #0b7ad1"-->
<!--                >-->
<!--                  {{ item.fileName }}.pdf-->
<!--                </span>-->
<!--                </section>-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <span>No hay documentos</span>-->
<!--              </div>-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              <span>No hay documentos</span>-->
<!--            </template>-->
          </div>

          <div class="flex flex-col">
            <span class="font-bold">Tipo Doc.: </span>
            <span>{{ impugnacion.tipoDoc }}</span>
          </div>
          <div class="flex flex-col">
            <span class="font-bold"># Identificación: </span>
            <span>{{ impugnacion.numeroIdentificacion }}</span>
          </div>
        </div>
        <div class="mt-4 mb-4 bg-blue-300 h-0.5" v-if="fallosByTutela.Impugnacion.length > 1 && fallosByTutela.Impugnacion.length !== (key + 1)"></div>
      </div>

    </AccordionTab>

    <AccordionTab header="SEGUNDA INSTANCIA" v-if="verSegundaInstancia">
      <div v-if="addSegundaInstancia" class="flex flex-col items-center gap-2">
        <span class="font-medium text-lg">Crear Fallo Segunda Instacia</span>
        <div class="grid grid-cols-3 grid-rows-2 gap-2 mb-2">

          <div class="flex flex-col justify-center">
            <label class="font-medium text-sm">Fecha de Notificación:</label>
            <input class="p-inputtext w-full"
                   type="datetime-local"
                   v-model="modelFallos.fechaNotificacion"
            />
            <MessageError :text="errorsFallos.fechaNotificacion"/>
          </div>

          <div class="flex flex-col col-span-3">
            <label class="font-medium text-sm">Observaciones</label>
            <Textarea
              v-model="modelFallos.observacion"
              placeholder="Observaciones"
              class="w-full"/>
            <MessageError :text="errorsFallos.observacion"/>
          </div>
          <div class="flex justify-between items-center" v-for="(item, key) in adjuntos" :key="key">
            <div class="flex justify-between items-center mt-2">
              <i class="pi pi-file-pdf mr-2 text-black"></i>
              <p>{{ item.fileName }}</p>
            </div>
            <div>
              <button><i class="pi pi-trash mr-2 text-red-600"></i></button>
            </div>
          </div>
        </div>
        <div class="flex gap-2 w-1/3">
          <button icon="pi pi-external-link"
                  v-if="$can('pharmasan.administrativa.juridica.tutelas.access')"
                  @click="openMaximizable"
                  class="w-full py-2 bg-white hover:bg-gray-200 border border-solid border-gray-400">
            Agregar soporte
          </button>
          <Button icon="pi pi-save"
                  v-if="$can('pharmasan.administrativa.juridica.tutelas.access')"
                  label="Guardar"
                  class="w-full"
                  @click="onGuardarFalloImpugnacion()"
          />
          <Button icon="pi pi-times"
                  label="Cancelar"
                  class="w-full p-button-warning"
                  @click="addSegundaInstancia = false"
          />
        </div>
        <div class="mt-4 mb-4 bg-blue-300 h-0.5 w-full" v-show="listSegundaImpugnacion.length > 0"></div>

      </div>

      <div v-for="(item, key) in listSegundaImpugnacion" :key="key">
        <div class="grid grid-cols-4 text-sm grid-cols-5 grid-rows-2 gap-2">
          <div class="flex flex-col">
            <span class="font-bold">Id: </span>
            <span>{{ item.id }}</span>
          </div>
          <div class="flex flex-col">
            <span class="font-bold">Fecha Notificacion: </span>
            <span>{{ item.fechaNotificacion }}</span>
          </div>
          <div class="flex flex-col col-span-2 row-span-2">
            <span class="font-bold">Observaciones: </span>
            <span>{{ item.observacion }}</span>
          </div>
          <div class="flex flex-col">
            <span class="font-bold">Acciones: </span>
            <div class="flex gap-2">
              <Button v-if="$can('pharmasan.administrativa.juridica.tutelas.access')" icon="pi pi-pencil" v-tooltip.top="'Editar fallo'" class="p-button-rounded p-button-warning"
                      @click="onEditar(item.id)"/>
              <Button icon="pi pi-file-pdf" v-tooltip.top="'Ver Soportes'" class="p-button-rounded p-button-info"
                      @click="verSoportesFallo(item.paths, item.id)"/>
            </div>
          </div>
          <div class="flex flex-col">
            <span class="font-bold">Creado Por: </span>
            <span>{{ item.createdByNameFallos.fullName }}</span>
          </div>
          <div class="flex flex-col">
            <span class="font-bold">Fecha Vencimiento: </span>
            <span>{{ item.fechaVencimiento }}</span>
          </div>
          <div class="flex flex-col">
<!--            <span class="font-bold">Documentos: </span>-->
<!--            <template v-if="item.paths.length > 0">-->
<!--              <section v-for="(item, key) in item.paths" :key="key">-->
<!--                  <span class="cursor-pointer hover:underline"-->
<!--                        @click="verPdf(item.urlPath)"-->
<!--                        style="color: #0b7ad1"-->
<!--                  >-->
<!--                    {{ item.fileName }}.pdf-->
<!--                  </span>-->
<!--              </section>-->
<!--            </template>-->
          </div>
        </div>
        <div class="mt-4 mb-4 bg-blue-300 h-0.5" v-if="listSegundaImpugnacion.length > 1 && listSegundaImpugnacion.length !== (key + 1)"></div>
      </div>
    </AccordionTab>
  </Accordion>

  <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
    </template>
  </Dialog>

  <Dialog header="Soportes" v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '30vw'}" :modal="true">
    <section v-if="soportes.length">
      <div v-for="(soporte, i) in soportes" :key="i">
        <div class="flex justify-between">
          <div>
            <p>
              {{ soporte.fileName }}
            </p>
          </div>
          <div>
            <button @click="verPdf(soporte.urlPath)">
              <i class="pi pi-eye text-blue-600"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      No hay soportes
    </section>
    <template #footer>
      <Button label="Agregar Soporte" class="p-button-warning" @click="addSoporte" />
      <Button label="OK" @click="displayModal = false" autofocus/>
    </template>
  </Dialog>

  <Dialog header="Soportes" v-model:visible="displayModalFallos" :breakpoints="{'960px': '75vw', '640px': '90vw'}"
          :style="{width: '30vw'}" :modal="true">
    <section v-if="soportes.length">
      <div v-for="(soporte, i) in soportes" :key="i">
        <div class="flex justify-between">
          <div>
            <p>
              {{ soporte.fileName }}
            </p>
          </div>
          <div>
            <button @click="verPdf(soporte.urlPath)">
              <i class="pi pi-eye text-blue-600"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section v-else>
      No hay soportes
    </section>
    <template #footer>
      <Button label="Agregar Soporte" class="p-button-warning" @click="addSoporteFallos" />
      <Button label="OK" @click="displayModalFallos = false" autofocus/>
    </template>
  </Dialog>

  <Dialog header="Agregar soportes" v-model:visible="modalAgregarSoportesVisible" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeAgregarSoportes" autofocus />
    </template>
  </Dialog>
  <Dialog header="Agregar soportes" v-model:visible="modalAgregarSoportesVisibleFallos" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeAgregarSoportesFallos" autofocus />
    </template>
  </Dialog>

</template>

<script>
import fallosStore from '../../../../../../store/fallos.store'
import { computed, onMounted, ref } from 'vue'
import Swal from 'sweetalert2'
import { useRouter, useRoute } from 'vue-router'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'
import DocTipoService from '@/apps/pharmasan/juridica/procesosJuridicos/services/docTipo.service'
import FilePond from '../../../../../uploadFilePoundNew/index.vue'
import FilePondStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/filepond.store'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import impugnacionStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/impugnaciones.store'
import { useToast } from 'primevue/usetoast'

export default {
  name: 'TablaFallos',
  components: {
    FilePond
  },
  setup () {
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const _PdfService = new PdfService()
    const _DocTipoService = new DocTipoService()
    const displayMaximizable = ref(false)
    const modalAgregarSoportesVisible = ref(false)
    const modalAgregarSoportesVisibleFallos = ref(false)
    const activeIndex = ref(0)
    const docTipos = ref([])
    const adjuntos = ref([])
    const title = ref('')
    const pond = ref()
    const verPrimeraInstancia = ref(false)
    const verImpugnacion = ref(false)
    const verSegundaInstancia = ref(false)
    const addImpugnacion = ref(false)
    const addSegundaInstancia = ref(false)
    const listSegundaImpugnacion = ref([])
    const idNuevaSegundaInstancia = ref(null)
    const displayModal = ref(false)
    const displayModalFallos = ref(false)
    const soportes = ref([])
    const idImpugnacion = ref(null)
    const paths = ref([])

    const fallosByTutela = computed(() => {
      return fallosStore.getters.fallosByTutela
    })
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const ver = ({ tipo, base }) => {
      if (tipo === 1) {
        router.push({ name: 'pharmasan.administrativa.juridica.tutelas.ver', params: { id: base } })
      } else {
        alert('Falta el de incidencias')
      }
    }

    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        adjuntos.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        displayMaximizable.value = false
        title.value = ''
      })
    }

    const onEditar = (id) => {
      router.push({ name: 'pharmasan.administrativa.juridica.tutelas.fallos.editar', params: { id } })
    }

    const onEditarImpugnacion = (id) => {
      router.push({ name: 'pharmasan.administrativa.juridica.tutelas.fallos.impugnaciones.editar', params: { id } })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      fallosStore.dispatch('getPaginate')
    }

    const remove = ({ id }) => {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Si se elimina este juzgado no se puede recuperar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          fallosStore.dispatch('removeFallo', id).then(() => {
            // activeIndex.value = null
            fallosStore.dispatch('findFalloByTutela', route.params.id)
            Swal.fire(
              'Eliminado!',
              'El fallo fue eliminada con exito.',
              'success'
            )
          })
        }
      })
    }

    const openMaximizable = () => {
      displayMaximizable.value = true
    }

    /** state **/
    // Schema Impugnacion
    const schema = yup.object({
      nombre: yup.string().nullable().required('El campo es requerido').label(''),
      tipoDoc: yup.string().nullable().required('El campo es requerido').label(''),
      numeroIdentificacion: yup.string().nullable().required('El campo es requerido').label(''),
      fechaImpugnacion: yup.date('No es una fecha permitido').nullable().required('El campo es requerido').label(''),
      observaciones: yup.string().nullable().required('El campo es requerido').label('')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('nombre')
    useField('tipoDoc')
    useField('numeroIdentificacion')
    useField('fechaImpugnacion')
    useField('observaciones')

    const onGuardarImpugnacion = handleSubmit(async (values) => {
      // activeIndex.value = 0
      if (!values) return console.error('Error => validation')
      values.tipo = 3
      values.base = fallosByTutela.value.id
      values.paths = adjuntos.value
      impugnacionStore.dispatch('createImpugnacion', values).then(({ data }) => {
        if (data.error) {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Error al crear solicitud', life: 3000 })
        } else {
          Swal.fire({
            title: 'Exito',
            text: 'Impugnacion creada con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            fallosStore.dispatch('findFalloByTutela', route.params.id)
            addImpugnacion.value = false
            adjuntos.value = []
          })
        }
      })
    })

    // Schema Fallos Impugnacion
    const schemaFallos = yup.object({
      fechaNotificacion: yup.date('No es una fecha permitido').nullable().required('El campo es requerido').label(''),
      // fechaVencimiento: yup.date('No es una fecha permitido').nullable().required('El campo es requerido').label(''),
      observacion: yup.string().nullable().required('El campo es requerido').label('')
    })
    const { errors: errorsFallos, values: modelFallos, handleSubmit: handleSubmitFallos } = useForm({
      validationSchema: schemaFallos
    })
    useField('fechaNotificacion')
    // useField('fechaVencimiento')
    useField('observacion')

    const onGuardarFalloImpugnacion = handleSubmitFallos(async (values) => {
      if (!values) return console.error('Error => validation')
      values.tipo = 8
      values.base = idNuevaSegundaInstancia.value
      values.paths = adjuntos.value
      fallosStore.dispatch('createFallo', values).then(({ data }) => {
        if (data.error) {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Error al crear solicitud', life: 3000 })
        } else {
          Swal.fire({
            title: 'Exito',
            text: 'Fallo creado con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then(() => {
            fallosStore.dispatch('findFalloByTutela', route.params.id).then(() => {
              addSegundaInstancia.value = false
              adjuntos.value = []
              console.log(fallosByTutela.value)
              listSegundaImpugnacion.value = []
              fallosByTutela.value.Impugnacion.map((item) => {
                if (item.Fallo) {
                  listSegundaImpugnacion.value.push(item.Fallo)
                }
              })
            })
          })
        }
      })
    })

    const crearImpugnacion = () => {
      verImpugnacion.value = true
      activeIndex.value = 1
      addImpugnacion.value = true
    }

    const crearSegundaInstancia = (id) => {
      activeIndex.value = 2
      addSegundaInstancia.value = true
      verSegundaInstancia.value = true
      idNuevaSegundaInstancia.value = id
    }

    const verSoportes = (paths, id) => {
      displayModal.value = true
      soportes.value = paths
      idImpugnacion.value = id
    }

    const verSoportesFallo = (paths, id) => {
      displayModalFallos.value = true
      soportes.value = paths
      idImpugnacion.value = id
    }

    const addSoporte = () => {
      displayModal.value = false
      modalAgregarSoportesVisible.value = true
    }

    const addSoporteFallos = () => {
      displayModalFallos.value = false
      modalAgregarSoportesVisibleFallos.value = true
    }

    const closeAgregarSoportes = () => {
      pond.value.onSubmitSupport().then(async () => {
        paths.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        await _PdfService.create({
          tipoNombre: 'impugnacion',
          tipo: 8,
          base: idImpugnacion.value,
          fileName: paths.value[0].fileName,
          urlPath: paths.value[0].urlPath
        })
        await getFallos()
        modalAgregarSoportesVisible.value = false
        displayMaximizable.value = false
        title.value = ''
      })
    }

    const closeAgregarSoportesFallos = () => {
      pond.value.onSubmitSupport().then(async () => {
        paths.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        await _PdfService.create({
          tipoNombre: 'fallo',
          tipo: 3,
          base: idImpugnacion.value,
          fileName: paths.value[0].fileName,
          urlPath: paths.value[0].urlPath
        })
        await getFallos()
        modalAgregarSoportesVisibleFallos.value = false
        displayMaximizable.value = false
        title.value = ''
      })
    }

    const getFallos = () => {
      fallosStore.dispatch('findFalloByTutela', route.params.id).then(() => {
        fallosByTutela.value ? verPrimeraInstancia.value = true : verPrimeraInstancia.value = false
        if (fallosByTutela.value.Impugnacion) {
          if (fallosByTutela.value.Impugnacion.length > 0) {
            verImpugnacion.value = true
            // const segundaInstancia = data.value.find((item) => item.Fallo)
            listSegundaImpugnacion.value = []
            for (let i = 0; i < fallosByTutela.value.Impugnacion.length; i++) {
              if (fallosByTutela.value.Impugnacion[i].Fallo) {
                verSegundaInstancia.value = true
                listSegundaImpugnacion.value.push(fallosByTutela.value.Impugnacion[i].Fallo)
              }
            }
          } else {
            verImpugnacion.value = false
          }
        } else {
          verImpugnacion.value = false
        }
      })
    }

    onMounted(() => {
      _DocTipoService.getAll().then(({ data }) => {
        docTipos.value = data
      })
      fallosStore.dispatch('findFalloByTutela', route.params.id).then(() => {
        fallosByTutela.value ? verPrimeraInstancia.value = true : verPrimeraInstancia.value = false
        if (fallosByTutela.value.Impugnacion) {
          if (fallosByTutela.value.Impugnacion.length > 0) {
            verImpugnacion.value = true
            listSegundaImpugnacion.value = []
            for (let i = 0; i < fallosByTutela.value.Impugnacion.length; i++) {
              if (fallosByTutela.value.Impugnacion[i].Fallo) {
                verSegundaInstancia.value = true
                listSegundaImpugnacion.value.push(fallosByTutela.value.Impugnacion[i].Fallo)
              }
            }
          } else {
            verImpugnacion.value = false
          }
        } else {
          verImpugnacion.value = false
        }
      })
    })

    return {
      fallosByTutela,
      onPage,
      remove,
      onEditar,
      onEditarImpugnacion,
      verPdf,
      ver,
      displayMaximizable,
      onGuardarImpugnacion,
      addSoporte,
      closeMaximizable,
      openMaximizable,
      activeIndex,
      adjuntos,
      title,
      docTipos,
      pond,
      errors,
      model,
      closeAgregarSoportes,
      errorsFallos,
      modelFallos,
      onGuardarFalloImpugnacion,
      verPrimeraInstancia,
      verImpugnacion,
      verSegundaInstancia,
      addImpugnacion,
      addSegundaInstancia,
      crearImpugnacion,
      crearSegundaInstancia,
      listSegundaImpugnacion,
      displayModal,
      soportes,
      modalAgregarSoportesVisible,
      modalAgregarSoportesVisibleFallos,
      verSoportes,
      verSoportesFallo,
      displayModalFallos,
      addSoporteFallos,
      closeAgregarSoportesFallos
    }
  }
}
</script>

<style scoped>
::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-accordion-content) {
  background-color: white;
}
</style>
